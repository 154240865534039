/* ---------------------------------------------------------
	
	* Filename:			  feature-2.scss
	* Description: 		Define the layout for feature-2
  * Version:				1.0.1
  * Last Modified:  3.28.2019
	
------------------------------------------------------------*/
.feature.feature--3 .feature__image { width: 100%; height: 100%; -o-object-fit: cover; object-fit: cover; }

/** BEGIN COMPONENT OVERRIDES HERE **/

.feature--3{
    margin-bottom: 4rem;
}