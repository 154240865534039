.revieews{
    /* margin-bottom: 10rem; */
}


.reviewBTN {
    margin-bottom: 6rem;
}

.reviewBTN {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  
  .review {
    border: 5px solid #77368e;
    padding: 10px;
    margin-top: 10px;
    width: 100%;
  }

  .reviewBTN img{
      width: 100% !important;
  }