*{
  /* overflow-x: hidden !important; */

}

body{
  margin: 0;
  padding: 0;
  /* width: 103%; */
  /* overflow-x: hidden !important; */
}

p {
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

h1, h2, h3, h4{
  margin: 0;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}


img{
  max-width: 100% !important;
  /* overflow: hidden; */
}


