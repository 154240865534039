

/* ---------------------------------------------------------
	
	* Filename:			  feature-5.scss
	* Description: 		Define the layout for feature-5
  * Version:				1.0.0
  * Last Modified:  4.11.2019
	
------------------------------------------------------------*/
.feature.feature--5 { position: relative; height: 600px;background: #bab4d4; }

@media screen and (max-width: 767px) { .feature.feature--5 { height: auto; padding: 2rem 0; } }


.feature.feature--5 div[class^="container"], .feature.feature--5 div[class^="row"] { height: 100%; }

.feature.feature--5 div[class^="col"].absolute { position: absolute; height: 100%; }

.feature.feature--5 div[class^="col"].absolute.absolute--left { left: 0; padding-left: 0; }

@media screen and (max-width: 767px) { .feature.feature--5 div[class^="col"].absolute { position: relative; right: initial; padding: initial; } }

.feature.feature--5 .feature__image { width: 100%; height: 100%; -o-object-fit: cover; object-fit: cover; }

.feature.feature--5 .feature__content { display: -webkit-box; display: -webkit-flex; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -webkit-flex-direction: column; -ms-flex-direction: column; flex-direction: column; -webkit-box-pack: center; -webkit-justify-content: center; -ms-flex-pack: center; justify-content: center; height: 100%; }

/** BEGIN COMPONENT OVERRIDES HERE **/




.call-out{
    background: #77368e;
}

.call-out h2, .call-out p{
    color: #fff;
}

.call-out {
    background: #77368e;
    /* width: 605px; */
    position: relative;
    left: 40rem;
    height: 9rem;
    padding: 2rem;
    bottom: 7rem;
    display: flex;
    align-items: center;
}

.call-out img{
    filter: invert(100%) sepia(0%) saturate(7466%) hue-rotate(177deg) brightness(111%) contrast(108%);
width: 100px;

}

.call-out h2{
    font-size: 2rem;
    font-size: 2rem;
    margin: 0 3rem;
    font-weight: 400;
}

.call-out h2 span{
    text-transform: uppercase;
    font-weight: 600;
}
.call-out p{
    line-height: 2.3;
}

.call-out a{
    margin-left: 2rem;
}



.feature.feature--5 h2, .feature.feature--5 p{
    color: #fff;
}

@media screen and (max-width: 575px) {
    .feature.feature--5 h2{
        font-size: 2rem;
    }
}

@media screen and (max-width: 1700px) {
    .call-out{
        left: 35rem;
    }
}
@media screen and (min-width: 1310px){
    .call-out{
        width: 1057px;
    }
}

@media screen and (max-width: 1550px) {
    .call-out{
        left: 20rem;
        top: -5rem;
    }
}

@media screen and (max-width: 1310px) {
    .call-out{
        left: 0rem;
        top: -5rem;
    }
}

@media screen and (max-width: 991px) {
    .call-out{
flex-direction: column;
height: auto;
top: 0;    margin: 5rem 0 2rem;
    }
    .call-out a {
        margin-left: 0rem;
    }

.call-out h2 {

    text-align: center;
}
}

.feature.feature--5 h2{
    color: #77368e;
  }
  .feature.feature--5 ul li{
      margin-bottom: 1rem;
  }

  .feature.feature--5 h2{
      text-align: center;
  }

  .feature.feature--5 .content__buttons{
    display: flex;
    align-items: center;
    justify-content: center;
  }