/* ---------------------------------------------------------
  
  * Filename:			  page-title-left-box.scss
  
------------------------------------------------------------*/
.inner-banner .container, .inner-banner .container > .row { height: 100%; }

@media screen and (max-width: 767px) { .inner-banner { height: 400px; } }

.inner-banner { position: relative; height: 200px; background: #77368e; margin-bottom: 4rem; }

.inner-banner .container, .inner-banner .container > .row { height: 100%; }

@media screen and (max-width: 767px) { .inner-banner { height: 400px; } }

.page-title.page-title-left-box { position: absolute; bottom: 0; left: 0; width: 100%; text-align: left; overflow: hidden; padding-left:15px; }

.page-title.page-title-left-box .page-title__text { display: inline-block; margin: 0; color: white; background: #bab4d4; }

/** BEGIN COMPONENT OVERRIDES HERE **/


.page-title.page-title-left-box::before {
    content: "";
    position: absolute;
    background: #bab4d4;
    left: -198%;
    width: 200%;
    top: 0;
    bottom: 0;
    display: block;
}

.page-title.page-title-left-box {
    overflow: inherit;
}
.page-title.page-title-left-box {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: left;
}

.page-title.page-title-left-box .page-title__text {
    padding: 10px 25px 10px 0;
    font-size: 2.25rem;
    font-weight: bold;
    text-shadow: 2px 2px 10px rgba(0,0,0,0.4);
}
.page-title.page-title-left-box .page-title__text {
    display: inline-block;
    margin: 0;
    color: white;
    background: #bab4d4;
}

.page-title.page-title-left-box .page-title__text{
    position: relative;
}

.page-title__text{
    text-transform: uppercase;
}