.Hamburger {
    width: 100%;
    height: 5em;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    padding: 1em;
    position: fixed;  /* Add this to make it sticky */
    top: 0;           /* Stick to the top of the viewport */
    z-index: 100;     /* Adjust the z-index as needed */
    display: none;
}

.burger-menu {
    height: 100%;
    width: 4em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    cursor: pointer;
}

.burger-bar {
    width: 4em;
    height: 0.5em;
    background-color: #77368e;
    border-radius: 0.5em;
}

.menu {
    width: 60%;
    height: 100vh;
    background-color: #bab4d4;
    position: fixed;   /* Add this to make the menu sticky */
    top: 5em;          /* Position it right below the navigation bar */
    z-index: 99;      /* Adjust the z-index to be lower than the navigation bar */
    right: 0;
}

/* ------------- sliding menu ------------------ */
.hidden {
    display: none;
}

.visible {
    display: inherit;
}

/* ----- animations--------- */
/* clicked */
.burger-bar.clicked:nth-child(1){
    transform: rotate(45deg) translate(0.75em, 1.25em);
    transition: ease-out 0.5s;
}

.burger-bar.clicked:nth-child(2){
    transform: scale(0.1);
    transition: ease-out 0.5s;
}

.burger-bar.clicked:nth-child(3){
    transform: rotate(135deg) translate(-0.5em, 1em);
    transition: ease-out 0.5s;
}

/* unclicked */
.burger-bar.unclicked {
    transform: rotate(0) translate(0);
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}

@media screen and (max-width: 991px) {
    .Hamburger {
        display: flex !important;
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}



  
  .menu li a{
    font-size: 23px;
    color: rgb(37, 37, 37);
    text-transform: uppercase;
    list-style-type: none;  text-decoration: none; 
  }

  .menu li{
      text-decoration: none;    list-style-type: none;border-bottom: 1px solid #1f1f1f;    margin: 1rem 0;
  }
  .menu ul{
      padding-left: 10px;
  }



